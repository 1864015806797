import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { sample } from 'lodash'
import { useViewportScroll, motion, useTransform } from 'framer-motion'
import { useMeasure, useMount, useWindowScroll, useUnmount } from 'react-use';
import { Element, scroller } from 'react-scroll';
import { withHeader } from '../components/Header/HeaderContext';

import { Block } from '../components'

import { Layout } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { parseACF } from '../utils'
import { legacyHeading2, subheading } from '../styles/type'
import { media, isClient, useBreakpoint } from '../styles/utils'

const Index = (props) => {
	const { headerVisible, setHeaderContext } = props;
	const [introText, setIntroText] = useState(null)
	const [reloaded, setReloaded] = useState(false)
	const [hideIntro, setHideIntro] = useState(false)
	const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
	const isPhone = useBreakpoint('phone');
	const {scrollY} = useViewportScroll()
	const {x, y} = useWindowScroll();
	const [introRef, {height: introHeight, bottom}] = useMeasure();
	
	useMount(() => {
		setIntroText(sample(data.intro_text))

		setTimeout(() => {
			setHeaderContext({headerVisible: false})
		}, 0)
	
		if (isClient()) {
			if (sessionStorage.homeSeen == "true") {
				setHideIntro(true)
			} 

			sessionStorage.homeSeen = true;

			// Handle grid layout change on reload

			if (!isPhone) {
				if (sessionStorage.homeReloaded == "true") {
					setReloaded(true)
				}

				sessionStorage.homeReloaded = true
			}
		}
	})

	useUnmount(() => {
		setTimeout(() => {
			setHeaderContext({headerVisible: true})
		}, 100);
	})

	// Toggle menu icon 

	useEffect(() => {
		if (hideIntro) {
			setTimeout(() => {
				setHeaderContext({headerVisible: hideIntro})
			}, 100);
		}
	}, [hideIntro])

	// Handle grid layout change on reload

	useEffect(() => {	
		if (reloaded) sessionStorage.homeReloaded = false 
	}, [reloaded])

	// Header toggle

	useEffect(() => {
		if (!hideIntro) {
			if ((y >= bottom) && !headerVisible) setHeaderContext({headerVisible: true})
			if ((y < bottom) && headerVisible) setHeaderContext({headerVisible: false})
		} 
	}, [y])

	const renderIntro = () => {
		const opacity = useTransform(scrollY, [0, (bottom * 0.8)], [1, 0])

		return (
			<Intro
				ref={introRef}
				style={{opacity}} 
			>
				{introText && (
					<Description
						as={'h1'}
						dangerouslySetInnerHTML={{__html: introText.short_text}}
					/>
				)}

				<Subheading
					onClick={() => (
						scroller.scrollTo('grid', {
							duration: 1500,
							delay: 100,
							smooth: true,
						})
					)}
				>
					enter site
				</Subheading>
			</Intro>
		)
	}

	const renderGridBlocks = (blocks) => {
        if (!blocks) return;
        
		return blocks.map((block, i) => {  
			return (
				<Block
					layout={'grid_item'}
					display={true}
					key={i}
					flip={reloaded}
					{...block}
				/>
			)
		})
	}

	const renderGrid = () => {
		return (
			<Grid
				name={'grid'}
			>
				{renderGridBlocks(data.blocks)}
			</Grid>
		)
	}
	
	return (
		<Layout
			meta={data.seo}
		>
			<Wrapper>
				<Container
					style={{
						display: !hideIntro ? 'flex' : 'none'
					}}
				>
					{renderIntro()}
				</Container>
				
				{renderGrid()}
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}

	display: flex;
	flex-direction: column;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

// Intro

const Intro = styled(motion.div)`
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 132px 52px;
	will-change: opacity;

	${Description} {
		margin-top: auto;
		
		&, p {
			${legacyHeading2}
			text-align: center;

			${media.tablet`
				font-size: 18px;
			`}

			${media.phone`
				font-size: 15px;
			`}

			${media.phoneS`
				font-size: 13px;
			`}

			a {
				${hoverState}
			}
		}
	}

	${Subheading} {
		${subheading}
		margin-top: auto;
		cursor: pointer;
		${hoverState}
	}

	${media.phone`
		padding: 0 32px 52px; 
	`}

	${media.phoneS`
		padding: 0 24px 52px; 
	`}
`

// Grid

const Grid = styled(Element)`
	width: 100%;
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default withHeader(Index)